import React from "react";
import socials_fb from "../assets/images/socials_fb.png"
import socials_ig from "../assets/images/socials_ig.png"
import socials_tw from "../assets/images/socials_tw.png"
import socials_yt from "../assets/images/socials_yt.png"
import socials_tk from "../assets/images/socials_tk.png"
import socials_li from "../assets/images/socials_li.png"
import socials_pt from "../assets/images/socials_pt.png"

function Socials() {
  return (
    <div className="socials">
        <a href="https://www.facebook.com/organiklean/">
          <img className="socials_img" src={socials_fb} />
        </a>
        <a href="https://www.instagram.com/organiklean/">
          <img className="socials_img" src={socials_ig} />
        </a>
        <a href="http://www.twitter.com/organiklean">
          <img className="socials_img" src={socials_tw} />
        </a>
        <a href="https://www.youtube.com/channel/UCRjfeaW8wWcMSeODFBmOtsQ">
          <img className="socials_img" src={socials_yt} />
        </a>
        <a href="https://vm.tiktok.com/sQajBA/">
          <img className="socials_img" src={socials_tk} />
        </a>
        <a href="http://www.linkedin.com/">
          <img className="socials_img" src={socials_li} />
        </a>
        <a href="http://www.pintrest.com/">
          <img className="socials_img" src={socials_pt} />
        </a>
    </div>
  )
}

export default Socials;