import React from "react";
import Nav from "./nav"
import logo from '../assets/images/base_logo.png'
import '../assets/css/main.css'

class Header extends React.Component {
    state = {
        fixed: false
    };
    
  render() {
    return (
      <React.Fragment>
        <div id="header_bar" className="header">
          <div style={{float:"left", minWidth:"30vmin"}}>
            <a href="/">
              <img className="header_logo" src={logo}/>
            </a>
          </div>   
          {/* <Nav />                                         */}
        </div>
      </React.Fragment>
    );
  }
}


export default Header;
