import React from "react"
import Socials from "./Socials"
import logo from '../assets/images/base_logo.png'

function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="footer-container">
          <img className="footer_logo" src={logo} />
          <div className="details">
            <p><a href = "/contact">Contact Us</a></p>
            <p>07 3061 3032</p>
            <p>P.O. Box 470, Fortitude Valley, QLD, 4006</p>
            <Socials /> 
            <div className="copyright"><a href="http://tententhsdigital.com">TenTenths Digital</a> | © Copyright 2020</div>
          </div>          
        </div>
      </footer>
    </div>
  )
}

export default Footer
