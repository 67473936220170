import React, { Component } from 'react'
import { Location } from '@reach/router'
import { Link } from 'gatsby'
import { Menu, X } from 'react-feather'
import icon from '../assets/images/icon.png'
import '../assets/css/Nav.css'

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false
  }


  componentDidMount = () =>
    this.setState({ currentPath: this.props.location.pathname })

  handleMenuToggle = () => this.setState({ active: !this.state.active })

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active } = this.state,
      { subNav } = this.props,
      NavLink = ({ to, className, children, ...props }) => (
        <Link
          to={to}
          className={`NavLink ${
            to === this.state.currentPath ? 'active' : ''
          } ${className}`}
          onClick={this.handleLinkClick}
          {...props}
        >
          {children}
        </Link>
      )

    return (      
      <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
        <div className="Nav--Container container">          
          <div className="Nav--Links">            
            {/* <NavLink to="/about">About</NavLink>  */}
            <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'about' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('about') ||
                  this.props.location.pathname.includes('onlineyoga') ||
                  this.props.location.pathname.includes('yogatome') ||
                  this.props.location.pathname.includes('yogaposes') 
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('about')}
              >
                About
              </span>
              <div className="Nav--GroupLinks">
                <NavLink to="/about" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  About Us
                </NavLink>
                <NavLink to="/onlineyoga" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  Online Yoga
                </NavLink>
                <NavLink to="/yogatome" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  Yoga to Me
                </NavLink>  
                {/* <NavLink to="/yogaposes" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  Yoga Poses
                </NavLink>                 */}
              </div>
            </div>           
            <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'classes' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('classes') ||
                  this.props.location.pathname.includes('groupclasses') ||
                  this.props.location.pathname.includes('parkyoga') ||
                  this.props.location.pathname.includes('oneonone') ||
                  this.props.location.pathname.includes('corporatewellness')
                    ? 'active'
                    : ''
                }`}
                onClick={() => this.toggleSubNav('classes')}
              >
                Yoga with Allie
              </span>
              <div className="Nav--GroupLinks">
                <NavLink to="/classes" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  Classes 
                </NavLink>
                <NavLink to="/groupclasses" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  Group Classes
                </NavLink>
                <NavLink to="/parkyoga" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  Yoga in the Park
                </NavLink>
                <NavLink to="/oneonone" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  One on One
                </NavLink>
                <NavLink to="/corporatewellness" className="Nav--GroupLink">
                  <img className="nav_image" src={icon} /> 
                  Corporate Wellness
                </NavLink>
              </div>
            </div>
            <NavLink to="/timetable">Timetable</NavLink>
            {/* <NavLink to="/blog">Blog</NavLink> */}
            {/* <NavLink to="/membership">Membership</NavLink> */}
            <NavLink to="/contact">Contact</NavLink>
          </div>
          <button
            className="Button-blank Nav--MenuButton"
            onClick={this.handleMenuToggle}
          >
            {active ? <X /> : <Menu />}
          </button>
        </div>
      </nav>      
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
